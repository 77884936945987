var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:end",value:(_vm.getIndexMobile),expression:"getIndexMobile",arg:"end"}],staticClass:"slide-wrapper",on:{"click":_vm.getIndex}},[_c('ul',{ref:"carrosel",staticClass:"slide"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"arrow-nav",on:{"click":_vm.getIndex}},[_c('button',{staticClass:"prev"}),_c('button',{staticClass:"next"})]),_c('div',{staticClass:"sobre"},[_c('router-link',{attrs:{"to":{ name: `${_vm.slide}`, params: { slide: _vm.slide } }}},[_vm._v("Sobre o "+_vm._s(_vm.slide))]),_c('img',{staticClass:"arrow-img",attrs:{"src":require("../assets/arrow-red.svg"),"alt":"Arrow-Icon"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/projeto-06.png"),"alt":"ComexApp"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/projeto-1.png"),"alt":"Techno"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"animais"},[_c('img',{attrs:{"src":require("../assets/projeto-2.png"),"alt":"Animais Fantasticos"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"devfinances"},[_c('img',{attrs:{"src":require("../assets/projeto-3.png"),"alt":"Devfinances"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"clima"},[_c('img',{attrs:{"src":require("../assets/projeto-4.png"),"alt":"Clima Diario"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"bisnik"},[_c('img',{attrs:{"src":require("../assets/projeto-5.png"),"alt":"Bisnik"}})])
}]

export { render, staticRenderFns }