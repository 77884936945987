<template>
  <TheProjects />
</template>

<script>
import TheProjects from '@/components/TheProjects.vue';

export default {
  name: 'Projetos',
  components: {
    TheProjects,
  },
};
</script>

<style>
</style>