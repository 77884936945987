import { render, staticRenderFns } from "./Obrigado.vue?vue&type=template&id=1dcc8662&scoped=true&"
import script from "./Obrigado.vue?vue&type=script&lang=js&"
export * from "./Obrigado.vue?vue&type=script&lang=js&"
import style0 from "./Obrigado.vue?vue&type=style&index=0&id=1dcc8662&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dcc8662",
  null
  
)

export default component.exports